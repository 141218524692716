import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Greenfox from "../components/greenfox"
import Img from "gatsby-image"
import SEO from "../components/seo"
import ActionLink from "../components/action-link"

const IndexPage = ({data}) => (
  <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
    <SEO title="Opleiding stucadoor" />
    <h1>De grootste opleider in Europa waar je het vak van stukadoor kunt leren.</h1>
    <p>Traub stuc staat bekend om zijn niet schoolse aanpak. Naast hard werken, vinden wij een goede sfeer ook belangrijk. Wij geloven dat iedereen een talent heeft. Wij helpen je graag om een mooi vak te leren, zodat je altijd je eigen broek op kunt houden.</p>

    <p>Wij hebben een vestiging in <strong>Den Haag</strong> en in <strong>Breda</strong>. Kom gerust eens langs of maak een afspraak.</p>

    <ActionLink to="/about-us" >Meer over onze opleiding ›</ActionLink>

    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      <Img className="w-full my-8" fluid={data.traub_brothers.childImageSharp.fluid} />
      <Img className="w-full my-8 hidden lg:block" fluid={data.traub_rutte.childImageSharp.fluid} />
    </div>

    <h2>Wie is Traub Stuc?</h2>
    <p>Traub Stuc is in 1999 opgericht door de gebroeders Peet en Michel Traub. Gevoed door praktisch inzicht en een maatschappelijke instelling is gaandeweg het idee gerezen om jongeren op weg te helpen met competentiegericht onderwijs om ze het vak van stucadoor te leren.</p>
    
    <p>In 2010 zijn wij een opleidingsbedrijf gestart. Het concept is zo aangeslagen dat er inmiddels samenwerking bestaat met lokale overheden en regionale onderwijsinstellingen. Zo werken wij samen met <a href="https://socialreturn.nu" >GreenFox Social Return</a>, die onze jongeren begeleidt in hun eerste 6 maanden op de arbeidsmarkt. </p> 
    
    <Greenfox />
    
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    van: file(relativePath: { eq: "van_small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    traub_brothers: file(relativePath: { eq: "traub_brothers_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    traub_rutte: file(relativePath: { eq: "traub_rutte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`